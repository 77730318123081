import logo from "../../logo.svg";
import "./PharmacistDashboard.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import React, { useState, useEffect } from "react";
import { getBearer, getStatus } from "../../ApiCalls";

import { useSearchParams, useNavigate } from "react-router-dom";
import axios, { Axios } from "axios";
import PairCode from "../PairCode/PairCode";

function PharmacistDashboard() {
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [token, setToken] = useState("");
  const [pairCode, setPairCode] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  let nfcuid = searchParams.get("uid");

  const goToPatientDashboard = () =>
    navigate(`/patientdashboard/?uid=${nfcuid}`);

  let config2 = {
    method: "post",
    url: `https://api-staging.smrxt.net/integration/jones_hcg/0.0.1/devices/${nfcuid}/pair`,
    headers: {
      Authorization: "Bearer" + token,
    },
  };

  let payload = {
    nfcuid: nfcuid,
    token: token,
  };
  useEffect(() => {
    getBearer().then((res) => {
      setToken(res);
    });

    if (token) {
      getStatus(payload).then((res) => {
        setStatus(res);
      });
    }

    if (status === "unassigned") {
      axios.request(config2).then((response) => {
        setPairCode(response.data.pairing_key);
      });
    }

    if (status === "assigned") {
      goToPatientDashboard();
    }
  }, [token, status, nfcuid]);
  return (
    <div className="PharmacistDashboard">
      <Header></Header>
      <PairCode code={pairCode}></PairCode>
      <Footer></Footer>
    </div>
  );
}

export default PharmacistDashboard;
