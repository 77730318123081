import * as React from "react";
import Box from "@mui/material/Box";
import "./PairCode.css";
import PairCodeTypography from "../PairCodeTypography/PairCodeTypography";
import { Typography } from "@mui/material";
function PairCode(props) {
  return (
    <div className="paircode">
      <Typography sx={{ color: "#4593cf" }} variant="h4" component="h4">
        Pair Code
      </Typography>
      <Box
        sx={{
          width: 500,
          height: 100,
          backgroundColor: "#4593cf",
          "&:hover": {
            backgroundColor: "#4593cf",
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      >
        <PairCodeTypography code={props.code}></PairCodeTypography>
      </Box>
    </div>
  );
}
export default PairCode;
