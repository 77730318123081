import { ReactComponent as NomiHeaderSvg } from "../../assets/NomiHeader.svg";

function Header() {
  return (
    <div className="header">
      <NomiHeaderSvg></NomiHeaderSvg>
    </div>
  );
}
export default Header;
