import axios, { Axios } from "axios";
import moment from "moment";

export const getBearer = () => {
  return axios("https://smrxt-test.auth0.com/oauth/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: {
      audience: "https://api-staging.smrxt.net/integration",
      grant_type: "client_credentials",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    },
  })
    .then((response) => response.data.access_token)
    .catch((error) => {
      throw error;
    });
};

export const getStatus = (payload) => {
  return axios(
    `https://api-staging.smrxt.net/integration/jones_hcg/0.0.1/devices/${payload.nfcuid}/status`,
    {
      method: "get",
      headers: {
        Authorization: "Bearer" + payload.token,
      },
    }
  )
    .then((response) => response.data.status)
    .catch((error) => {
      throw error;
    });
};

export const getAdherence = (payload) => {
  return axios(
    `https://api-staging.smrxt.net/integration/jones_hcg/0.0.1/devices/${payload.nfcuid}/adherence`,
    {
      method: "get",
      headers: {
        Authorization: "Bearer" + payload.token,
      },
    }
  )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postConfirmation = (payload) => {
  return axios(
    `https://api-staging.smrxt.net/integration/jones_hcg/0.0.1/devices/record`,
    {
      method: "post",
      headers: {
        Authorization: "Bearer" + payload.token,
      },
      data: {
        amount: 1,
        timestamp: moment().toISOString(),
        dose_number: payload.doseno,
        nfc_tag_id: payload.nfcuid,
        idempotency_key: crypto
          .createHash("sha256")
          .update(moment().toISOString())
          .digest("hex"),
      },
    }
  )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
