import React, { useState, useEffect } from "react";

import "./ListItem.css";
import { Typography, Box, colors, Button, Grid } from "@mui/material";
import moment from "moment/moment";
import { postConfirmation } from "../../ApiCalls";
/*TODO:
  1. complete payload data that will go to the confirmation call
  2. complete button and onClick code
  3. clean up code and complete component

*/
function ListItem(props) {
  let payload = {
    token: props.token,
    nfcuid: props.nfcuid,
  };

  const onClick = () => {
    postConfirmation(payload);
  };
  return (
    <div className="centered-div">
      <Box
        sx={{
          borderRadius: "10%",
          paddingTop: "3%",
          paddingBottom: "3%",
          width: "100%",
          height: "10%",
          backgroundColor: "#4593cf",
          "&:hover": {
            backgroundColor: "#4593cf",
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs sx={{ marginLeft: "3%" }}>
            <Typography
              variant="h5"
              sx={{
                color: "white",
                textAlign: "left",
                paddingLeft: "2%",
                fontWeight: "bold",
              }}
            >
              <span>Dose {props.index + 1}</span>
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                textAlign: "left",
                paddingLeft: "2%",
              }}
            >
              {props.adherence ? (
                <span>
                  Scheduled For:{" "}
                  {moment
                    .utc(props.adherence.periods[0].dose_period_range.start_on)
                    .format("HH:mm")}
                </span>
              ) : (
                <span>Loading data...</span>
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ width: "10%", height: "50px", fontSize: "12px" }}
              variant="contained"
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
export default ListItem;
