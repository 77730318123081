import * as React from "react";
import Box from "@mui/material/Box";
import "./PairCodeTypography.css";
import { Typography } from "@mui/material";

function PairCodeTypography(props) {
  return (
    <div className="PairCodeTypography">
      <Typography sx={{ color: "white" }} variant="h3" component="h3">
        {props.code}
      </Typography>
    </div>
  );
}
export default PairCodeTypography;
